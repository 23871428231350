import { useState } from 'react';
import './App.css';
import Dialpad from './Dialpad/Dialpad';
// import NumberPicker from './NumberPicker/NumberPicker';
import { ToastContainer, toast } from 'react-toastify';
import { createCookie, readCookie } from './Helpers/Cookie';

function App() {
  const notifyError = (err) => toast.error(err);
  const notifySuccess = (success) => toast.success(success);

  // const [selectedNumber, setSelectedNumber] = useState("");

  // const REACT_APP_API_URL = 'https://swatiapi.letsendorse.net';
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  const initialDiallerNumber = readCookie('diallerNumber') || "";
  console.log("🚀 ~ file: App.js:18 ~ App ~ initialDiallerNumber:", initialDiallerNumber)

  const [diallerNumber, setDiallerNumber] = useState(initialDiallerNumber);
  const [diallingNumber, setDiallingNumber] = useState("");

  const handleSelectedNumber = (type, number) => {
    if (type === 'dialler') {
      setDiallerNumber(number);
    }
    else if (type === 'dialling') {
      setDiallingNumber(number);
    }
  }

  const placeCall = () => {
    if (!(diallerNumber && diallingNumber)) {
      notifyError('Both dialler and dialling numbers are mandatory!');
    } else if(diallerNumber.length !== 10) {
      notifyError('Please check dialler number');
    } else if (diallingNumber.length !== 10) {
      notifyError('Please check dialling number');
    } else {
      let EXOTEL_MAKECALL_URL = REACT_APP_API_URL + '/api/v1/call-logs/exotel/click-To-Call';
      fetch(EXOTEL_MAKECALL_URL, {
        method: "POST",
        headers: {
          // "x-api-key": "qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY",
          // Authorization: "f925251f-c0aa-4b89-bdf9-60f1b06ec111",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "from": "+91" + diallerNumber,
          "to": "+91" + diallingNumber.slice(diallingNumber.length - 10, diallingNumber.length),
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (!data.error) {
            notifySuccess(data.message);
            createCookie('diallerNumber', diallerNumber, 10);
            // setDiallingNumber('');
          } else {
            notifyError(`Couldn't make call!`);
          }
        });
    }
  }

  return (
<>      <h2 className='caller-header'>LE Caller</h2>
      <div className="flex-row">
        <ToastContainer />

      {/* <NumberPicker selectedNumber={selectedNumber} handleSelectedNumber={handleSelectedNumber} /> */}
      <div className='flex-column'>
          <Dialpad label={"Dialler Number"} number={diallerNumber}  dontShowCall={true} placeCall={placeCall} notifyError={notifyError} handleSelectedNumber={handleSelectedNumber} type={'dialler'} />
        </div>
      <div className='flex-column'>
        <Dialpad label={ "Dialling Number"} number={diallingNumber} placeCall={placeCall} notifyError={notifyError} handleSelectedNumber={handleSelectedNumber} type={'dialling'} />
        </div>
    </div>
    </> );
}

export default App;
