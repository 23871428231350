import React, { useEffect, useState } from "react";
import './Dialpad.css';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dialpad = ({ label, dontShowCall, type, handleSelectedNumber, notifyError, placeCall }) => {

    const [numberToDial, setNumberToDial] = useState("");
    

    useEffect(() => {
        handleSelectedNumber(type, numberToDial);
    }, [type, handleSelectedNumber, numberToDial]);

    const handleDigitClick = (e) => {
        const numberToSet = `${numberToDial}${e.target.id}`;
        if (numberToDial.length < 10) {
            setNumberToDial(numberToSet);
        } else {
            notifyError('Uh-oh! Seems like an invalid number :/');
        }
    }



    const resetDigits = () => {
        setNumberToDial("");
    }
    
    const clearADigit = () => {
        if (numberToDial.length < 2) {
            resetDigits();
        }
        else {
            const numberToSet = numberToDial.slice(0, numberToDial.length-1);
            setNumberToDial(numberToSet);
        }

    }
    const handleKeyBoardInput = (e) => {
        const numberToSet = `${e.target.value}`;
        if (numberToSet.length <= 10) {
            setNumberToDial(numberToSet);
        } else {
            notifyError('Uh-oh! Seems like an invalid number :/');
        }  
    }

    return <><link href="https://fonts.googleapis.com/css?family=Exo" rel="stylesheet"/>
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet"/>
        <div className="container">
            <div className="dialler-label">
                {label}
            </div>
            <div id="output">
                <input type="number" value={numberToDial} onChange={handleKeyBoardInput}>
                </input>
                </div>
                <div className="row">
                    <div className="digit" onClick={handleDigitClick} id="1">1</div>
                    <div className="digit" onClick={handleDigitClick} id="2">2
                        <div className="sub">ABC</div>
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="3">3
                        <div className="sub">DEF</div>
                    </div>
                </div>
                <div className="row">
                    <div className="digit" onClick={handleDigitClick} id="4">4
                        <div className="sub">GHI</div>
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="5">5
                        <div className="sub">JKL</div>
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="6">6
                        <div className="sub">MNO</div>
                    </div>
                </div>
                <div className="row">
                    <div className="digit" onClick={handleDigitClick} id="7">7
                        <div className="sub">PQRS</div>
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="8">8
                        <div className="sub">TUV</div>
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="9">9
                        <div className="sub">WXYZ</div>
                    </div>
                </div>
                <div className="row">
                <div className="digit"
                    // onClick={handleDigitClick}
                    id="*">
                    {/* * */}
                    </div>
                    <div className="digit" onClick={handleDigitClick} id="0">0
                    </div>
                <div className="digit"
                    // onClick={handleDigitClick}
                    id="#">
                    {/* # */}
                    </div>
                </div>
            <div className="botrow">
                <i className="fa fa-eraser dig" onClick={resetDigits} aria-hidden="true"></i>
               {dontShowCall? <div id="call-slash">
                    <i className="fa fa-phone-slash" aria-hidden="true"></i>  </div> :
                    <div id="call" onClick={placeCall}>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                     </div>}
                    <i className="fa fa-long-arrow-left dig" onClick={clearADigit} aria-hidden="true"></i>
                </div>
            </div></>
}


export default Dialpad;